import { userApi } from '@/services/api';

export const downloadZip = async (filename) => {
  try {
    await userApi
      .get(`/update/${filename}`, {
        responseType: 'blob',
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
  } catch (e) {
    return Promise.reject(e);
  }
};
