import { defineStore } from 'pinia';
import { User } from '@/models/User.model';

export const useAuthStore = defineStore('auth',  {
  state: () => ({
    /**
     * hydrate the state from the session if it exists otherwise null it
     */
    user: null,
    otpRequested: false,
    editLanguage: 'de',
  }),

  getters: {
    /**
     * Whether the user is logged in
     * @param {object} state Module state
     */
    isLoggedIn(state) {
      return state.user?.email !== null;
    },
  },

  actions: {
    /**
     * Set the OTP state
     *
     * @param {boolean} otp otp
     */
    setOTPState(otp) {
      this.otpRequested = otp;
    },

    /**
     * Set the user state
     *
     * @param {User} user user
     */
    setUser(user) {
      this.user = user;
    },

    /**
     * Set the edit language
     *
     * @param {string} language language
     */
    setEditLanguage(language) {
      this.editLanguage = language;
    },

    /**
     * Remove the login information and effectively log the user out
     */
    removeAuth() {
      this.user = null;
    },
    setAuth(loggedUser) {
      // Set the user to the state
      const user = new User(loggedUser);
      this.setUser(user);
    },
    changeEditLanguage(language) {
      this.setEditLanguage(language);
    },
  },
});
