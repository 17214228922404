<template>
  <form>
    <forge-input
      v-model="v$.email.$model"
      :value="email"
      class="mt-4"
      class-input="w-full"
      label="E-Mail"
      is-block
      required
      @blur="v$.email.$touch"
    >
      <validation-text v-if="v$.email.$error" class="mt-1">
        {{ $t('login.emailRequired') }}
      </validation-text>
    </forge-input>

    <forge-input
      v-model.trim.lazy="v$.password.$model"
      class="w-full mt-4"
      class-input="w-full"
      :value="password"
      :label="$t('login.password')"
      type="password"
      is-block
      required
      @blur="v$.password.$touch"
    >
      <validation-text v-if="v$.password.$error" class="mt-1">
        {{ $t('login.passwordRequired') }}
      </validation-text>
    </forge-input>

    <div class="flex items-center justify-between mt-6">
      <button
        class="btn btn--primary"
        :disabled="v$.$invalid"
        @click.prevent="doLogin"
      >
        {{ $t('login.submitButtonText') }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAuthStore } from '@/store/Auth';
import { getOTP } from '@/services/auth.js';
import { toaster } from '@/globals/toaster.js';
import ForgeInput from '@/components/app/Input.vue';
import ValidationText from '@/components/app/ValidationText.vue';

const emit = defineEmits(['login']);

const router = useRouter();
const { t } = useI18n();

const authStore = useAuthStore();

const email = ref('');
const password = ref('');

const rules = { email: { required }, password: { required } };
const v$ = useVuelidate(rules, { email, password });

const doLogin = async () => {
  try {
    const response = await getOTP(email.value, password.value);
    
    if ('token' in response.data && response.status == 201) {
      authStore.setOTPState(true);
      emit('login', email.value, password.value);
    }
    if ('email' in response.data && response.status == 200) {
      authStore.setAuth(response.data);
      // todo: use property on user model. This did not work right away
      // for demo purposes this is a solid workarround
      if (response.data.updates && response.data.updates.length > 0) {
        router.push({ name: 'licenseeList' });
      } else {
        router.push({ name: 'questionList' });
      }
    }
  } catch (e) {
    toaster('error', t('login.error'));
  }
}
</script>
