<template>
  <div class="min-h-screen font-sans app-master">
    <nav class="app-sidebar app-headerbar relative flex-none w-full text-white">
      <div class="flex justify-between px-16">
        <div class="flex flex-wrap items-center">
          <router-link class="" :to="{ name: 'questionList' }">
            <div :class="frontend_tag" class="app-logo">
              <img
                :src="logoPath"
                :title="frontend_tag"
                class="py-4"
                alt="logo"
              />
            </div>
          </router-link>
          <!-- <a
            href="http:liip.ch"
            class="headerbar__item ml-12 mr-6 no-underline hover:underline"
            >Theoriefragen-1</a
          >
          <a
            href="http:liip.ch"
            class="headerbar__item no-underline hover:underline"
            >Theoriefragen-2</a
          > -->
        </div>
        <button
          v-if="isLoggedIn"
          class="flex items-center hover:underline"
          @click="logoutUser"
        >
          <svg-icon
            class="inline-block w-10 h-10 p-2 text-whitesvg-icon svg-icon--door-open mr-1"
            name="door-open"
          />
          {{ $t('general.logout') }}
        </button>
      </div>
    </nav>
    <div class="bg-gray-200 flex flex-col flex-1">
      <main class="pt-10">
        <slot></slot>
      </main>
      <footer class="text-white p-4">
        <div class="flex flex-wrap p-16 pt-0 pb-0 justify-between">
          <a
            v-if="user && (user.isTranslator || user.isLector)"
            target="_blank"
            href="https://www.termdat.bk.admin.ch/Search/Search"
            class="text-white no-underline hover:underline"
            >{{ $t('general.glossary') }}</a
          >
          <span v-html="$t('general.copyright')"></span>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/Auth';
import { useLoadingStore } from '@/store/modules/Loading';
import LogoASA from '@/assets/images/ASA-short.svg?url';
import LogoVKS from '@/assets/images/VKS-short.svg?url';
import { logout } from '@/services/auth';

const router = useRouter();

const authStore = useAuthStore();
const loadingStore = useLoadingStore();

const user = computed(() => authStore.user);
const isLoggedIn = computed(() => authStore.isLoggedIn);

const logoPath = computed(() => {
  if (import.meta.env.VITE_FRONTEND_TAG === 'VKS') {
    return LogoVKS;
  } else {
    return LogoASA;
  }
});

const frontend_tag = computed(() => `${import.meta.env.VITE_FRONTEND_TAG}`);

const logoutUser = async () => {
  await logout();
  authStore.removeAuth();
  loadingStore.resetLoadingState();
  router.push({
    name: 'login',
  });
};
</script>

<style lang="scss">
/* TODO add this heading stuff to tailwind.js config */
h1 {
  font-weight: 500;
}

main {
  flex: 1;
}

footer {
  flex: 0;
  background-color: #363636;
}

footer a:hover {
  color: #fff;
}

.app-master {
  min-height: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-sidebar {
  height: 60px;
  background-color: #bb202b;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.4);
}

.glossary-link {
  bottom: 100px;
  font-size: 1.25rem;
}

.app-logo {
  max-width: 150px;

  & img {
    transform-origin: center;
    transform: scale(1);
    transition: 0.2s transform ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.headerbar__item {
}

.app-logo.ASA {
  background-color: #bb202b;
}

.app-logo.VKS {
  background-color: #00122d;

  &::after {
    border-color: #00122d transparent transparent transparent;
  }
}
</style>
