import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/Auth';
import { getCurrentUser } from '@/services/auth';
import routes from './routes.js';

let initial = true;
let auth;

const router = createRouter({
  history: typeof window !== 'undefined' ? createWebHistory(import.meta.env.BASE_URL) : 'http://forge.docker.test', 
  routes,
});

/**
 * Check the permissions of a user and route
 *
 * @param {object} to The route to navigate to
 */
const checkPermissions = to => {
  const authStore = useAuthStore(); 
  if (to.meta.requiresAuth && !authStore.isLoggedIn) {
    return false;
  }
  return true;
};

router.beforeEach(async (to, from, next) => {
  const goToPage = (routeName) => {
    return next({ name: routeName });
  };

  if (!auth) auth = useAuthStore();

  // Check if user is logged in on initial load
  if (initial) {
    initial = false;
    /* eslint-disable no-empty */
    try {
      const user = await getCurrentUser();    
      auth.setAuth(user.data);
    } catch { }
    /* eslint-enable no-empty */
  }

  if (!checkPermissions(to)) {
    return goToPage('login');
  }

  return next();
});

export default router;
