<template>
  <v-table
    :data="updates"
    class="bg-transparent border-none shadow-none update-table"
  >
    <template #head>
      <!-- number -->
      <v-th sort-key="id" class="filterable-header font-medium">
        <span>{{ $t('questions.updates.number') }}</span>
      </v-th>
      <!-- filename -->
      <v-th sort-key="filename" class="filterable-header font-medium">
        <span>{{ $t('questions.updates.filename') }}</span>
      </v-th>
      <th class="border-none"></th>
    </template>
    <template #body="{ rows }">
      <tr v-for="row in rows" :key="row.id" class="hover:bg-gray-100">
        <td class="pl-5">{{ row.id }}</td>
        <td>{{ row.filename }}</td>
        <td class="text-right pr-5">
          <button
            class="btn btn--primary"
            @click.prevent="downloadUpdateZip(row.filename)"
          >
            {{ $t('questions.updates.edit') }}
          </button>
        </td>
      </tr>
    </template>
  </v-table>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { downloadZip } from '@/services/Update.service';
import { toaster } from '@/globals/toaster.js';

defineProps({
  updates: {
    type: Array,
    default: null,
  },
});

const { t } = useI18n();

const downloadUpdateZip = async (filename) => {
  try {
    await downloadZip(filename);
  } catch (e) {
    toaster('error', t('questions.updates.error'));
  }
};
</script>

<style lang="postcss" scoped>
.update-table {
  border-collapse: separate;
  border-spacing: 0 1.25rem;

  & th {
    font-size: 0.75rem;
    color: #000;
  }

  & td {
    background-color: #fff;

    /*&:first-child {*/
    /*  width: 4rem;*/
    /*}*/
  }

  & tr td:first-child {
    border-top-left-radius: 0.375rem;
  }

  & tr td:last-child {
    border-top-right-radius: 0.375rem;
  }

  & tr td:first-child {
    border-bottom-left-radius: 0.375rem;
  }

  & tr td:last-child {
    border-bottom-right-radius: 0.375rem;
  }
}

.filterable-header {
  @apply border-none items-center cursor-pointer select-none;
  letter-spacing: 0.15px;

  & span {
    @apply inline-block float-left mr-2;
  }

  & :deep(svg) {
    @apply inline-block fill-current;
    margin-top: -3px;
    @apply text-primary;
  }
}
</style>
