import { userApi, userApiUnprotected } from './api';

/**
 * Perform a login and generate a OTP
 */
const getOTP = async (email, password) => {
  const response = await userApiUnprotected.post('account/login', {
    email: email,
    password: password,
  });

  return response
};

/**
 * Perform the actual login
 *
 * @return User data
 */
const login = async (email, password, otp) => {
  const response = await userApiUnprotected.post('account/token', {
    email: email,
    password: password,
    otp: otp,
  });

  return response
};


const getCurrentUser = async () => {
  const response = await userApi.get('account/me');

  return response
};

const logout = async () => {
  const response = await userApi.post('account/logout');
  return response;
};


export { getOTP, login, getCurrentUser, logout };
